<template>
  <div id="connect">
    <div class="f19 text-left mb30 text-bold">消息中心</div>
    <section class="card" ref="card">
      <div class="more pointer">查看更多...</div>
      <template v-for="(item, index) in 10">
        <div class="item mb20 flex" :key="index">
          <el-image
            class="logo mr20"
            fit="cover"
            :src="require('../../../assets/img/login/login_bg_r.png')"
          />
          <div class="text">
            <div class="name mb5">张三</div>
            <div class="content">你好，我购买的商品3天没发货。</div>
          </div>
        </div>
      </template>
    </section>
    <section class="input flex">
      <el-input
        type="textarea"
        :autosize="{ minRows: 2 }"
        placeholder="请输入"
        resize="none"
        v-model="msg"
        style="font-size: 17px"
      ></el-input>
      <el-button class="btn">留言</el-button>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: "",
    };
  },
  methods:{
    scrollToBottom(){
    let cardDom = this.$refs.card;
      cardDom.scrollTop = cardDom.scrollHeight;
    },
  },
  mounted() { 
  this.scrollToBottom()
  },
};
</script>

<style lang="less" scoped>
.card {
  height: 500px;
  overflow-y: auto;
  .item {
    .logo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .text {
      text-align: left;
      .name {
        font-size: 17px;
      }
      .content {
        padding: 7px;
        font-size: 17px;
        border-radius: 5px;
        background: white;
      }
    }
  }
}
.input {
  .btn {
    width: 110px;
    height: 64px;
    font-size: 17px;
    background: #f8b229;
    color: white;
  }
}
</style>