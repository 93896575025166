<template>
  <div id="declare">
    <section class="list flex flex-wrap" v-infinite-scroll="load" infinite-scroll-disabled="disabled">
      <div class="item mb30 mr30 f19" v-for="(item, index) in babyList" :key="index" >
        <!-- <el-image class="img mb10" fit="cover" src="item.createLogo" /> -->
        <div class="title_pic pointer" @click="jumpDetail(item)">
          <!-- <div class="text-left mb20 f17">
            {{ item.content }}
          </div> -->

          <div class="flex mb26 babyEnclosureList flex-wrap">
            <div class="title_status" :class="[item.status == '0' ? 'runing' : item.status == '1' ? 'pass' : 'reject']">
              {{ item.status == "0" ? "审核中" : item.status == "1" ? "审核通过" : "审核拒绝" }}
            </div>
            <el-image :src="item.babyEnclosureList[0].url" class="sitem_0" :class="[item.status == '2' ? 'sitem_00':''] "  fit="cover" />
            <div class="reason"  v-show="item.status==2">
              <span>拒绝理由：</span>
              <span >{{item.refusal}}</span>
            </div>
          </div>
        </div>
        <div :class="[item.status == '2' ? 'info':'']">
          <div class="flex align-center justify-between mb10">
            <div>{{ item.createTime | dataFormat }}</div>
            <div>{{ item.viewCount }}人浏览</div>
          </div>
          <div class="flex align-center justify-between">
            <div>
              <svg class="icon " aria-hidden="true">
                <use xlink:href="#icon-dianzan"></use></svg
              ><span class="ml5"> {{ item.likeCount }}</span>
            </div>
            <div @click="del(index)">
              <i class="el-icon-delete pointer" style="font-size: 20px; color: #bfbfbf" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading" class="bottomText">加载中...</div>
      <div v-if="noMore" class="bottomText">没有更多了</div>
    </section>
    <!-- <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="100"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前页数
      currPage: 1,
      // 每页显示多少数据
      pageSize: 6,
      pageCount: 0,
      count:0,
      loading: false,
      babyList: [],
    };
  },
  computed: {
    noMore() {
      return this.pageSize >= this.count;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    del(index) {
      var getData = {};
      getData.id = this.babyList[index].id;
      this.$api.notice.deleteBaby(getData).then((res) => {
        if ((res.data.code = 200)) {
          this.getBabyList();
        }
      });
    },
    jumpDetail(item) {
      //详情
      this.$router.push({
        path: "/partyBuilding/detail",
        query: {
          id: item.id,
        },
      });
    },
    load() {
      this.loading = true;
      this.pageSize+=8;
      this.getBabyList();
    },
    getBabyList() {
      const param = {
        currPage: this.currPage,
        pageSize: 8,
        condition: {
          memberCode: this.memberCode,
        },
      };
      this.$api.partyBuild.getBabyList(param).then((res) => {
        if (res.data.code === 200) {
          this.babyList =res.data.data.data;
          console.log('列表', this.babyList )
          this.pageCount = res.data.data.pageCount;
          this.loading = false;
        }
      });
    },//

   
  },
  created() {
    this.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
    this.getBabyList();
  },
};
</script>

<style lang="less" scoped>
.list {
  height: 660px;
  overflow-y: auto;
  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // width: 405px;
    width: 375px;
    height: 262px;
    // height: 420px;
    padding: 19px 21px;
    margin-bottom: 39px;
    border-radius: 5px;
    background: white;
    .babyEnclosureList {
      position: relative;
      overflow: hidden;
      .title_status {
        position: absolute;
        top: 0;
        right: 0;
        width: 100px;
        height: 30px;
        color: white;
        line-height: 30px;
        border-radius: 0 5px 0 5px;
        text-align: center;
        z-index: 2;
      }
    }

    .issuer {
      margin-bottom: 14px;
      .logo {
        width: 63px;
        height: 63px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    .sitem_0 {
      width: 341px;
      height: 156px;
      border-radius: 5px;
    }
    .reason{
     width: 250px;
     height: 120px;
     position: relative;
     top: -125px;
     left: 35px;
     z-index: 1;
     padding-left: 4px;
     border-radius: 5px;
     background:none;
     text-align: left;
     overflow-x: hidden;
     overflow-y: scroll;
    }
    ::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
    .sitem_00 {
     z-index: 1;
    filter: blur(5px);
    opacity: 0.6;
    }
    .sitem_1 {
      width: 383px;
      height: 383px;
      border-radius: 5px;
      overflow: hidden;
    }
    .sitem_2 {
      width: 383px;
      height: 192px;
      border-radius: 5px;
      overflow: hidden;
    }
    & .sitem_2:first-child {
      margin-bottom: 5px;
    }
    .sitem_3 {
      width: 383px;
      height: 125px;
      border-radius: 5px;
      overflow: hidden;
    }
    & .sitem_3:nth-child(2) {
      margin: 5px 0;
    }
    .sitem_4 {
      width: 176px;
      height: 188px;
      margin: 2.5px;
      border-radius: 5px;
      overflow: hidden;
    }
    .sitem_5 {
      width: 116px;
      height: 188px;
      margin: 2.5px;
      border-radius: 5px;
      overflow: hidden;
    }
    .sitem_6 {
      width: 116px;
      height: 188px;
      margin: 2.5px;
      border-radius: 5px;
      overflow: hidden;
    }

    .sitem_7 {
      width: 116px;
      height: 116px;
      margin: 2.5px;
      border-radius: 5px;
      overflow: hidden;
    }
    .sitem_8 {
      width: 116px;
      height: 116px;
      margin: 2.5px;
      border-radius: 5px;
      overflow: hidden;
    }
    .sitem_9 {
      width: 116px;
      height: 116px;
      margin: 2.5px;
      border-radius: 5px;
      overflow: hidden;
    }
  }
  .bottomText {
    width: 100%;
    text-align: center;
  }
}
.runing {
  background: orangered;
}
.pass {
  background: rgb(100, 165, 3);
}
.reject {
  background: red;
}
.reason span{
  font-size: 12pt;
  color: #000;
  text-align: left;
}
.info{
  position: relative;
  top: -125px;
}
</style>
