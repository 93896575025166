<template>
  <div id="logisticsInfo">
    <div class="title text-bold">快递：{{ express(wuliu.com) }}</div>
    <div class="title">运单号：{{ wuliu.nu }}</div>
    <section class="listBox">
      <el-timeline :reverse="false">
        <el-timeline-item
          class="infoList text-left"
          v-for="(item, index) in wuliu.data"
          :key="index"
          :timestamp="item.time"
          color="#54A7FC"
        >
          {{ item.context }}
        </el-timeline-item>
      </el-timeline>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    wuliu: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    express() {
      return (name) => {
        switch (name) {
          case "zhongtong":
            return "中通快递";
          case "yunda":
            return "韵达快递";
          case "shentong":
            return "申通快递";
          case "shunfeng":
            return "顺丰速运";
          case "yuantong":
            return "圆通快递";
          case "huitong":
            return "汇通快递";
          case "tiantian":
            return "天天快递";
            case "youzhengguonei":
            return "邮政快递包裹";
            case "ems":
            return "EMS";
             case "jd":
            return "京东物流";
             case "huitongkuaidi":
            return "百世快递";
             case "fengwang":
            return "丰网速运";
             case "debangwuliu":
            return "德邦";
             case "debangkuaidi":
            return "德邦快递";
            case "zhongtongguoji":
            return "中通国际";
             case "yundakuaiyun":
            return "韵达快运";
             case "danniao":
            return "丹鸟";
          default:
            return name;
        }
      };
    },
  },
  data() {
    return {
      infoList: [],
    };
  },
  created(){
  
  }
};
</script>

<style lang="less" scoped>
.title {
  padding: 20px 20px 13px;
  text-align: left;
  font-size: 29px;
  border-bottom: 2px solid #ebebeb;
}
.listBox {
  height: 510px;
  padding: 30px;
  overflow-y: auto;
  .infoList {
    text-align: left;
  }
}

.orange {
  color: #f45618;
}
</style>