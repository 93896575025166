<template>
  <div class="content">
    <section
      class="listBox flex flex-wrap"
      v-infinite-scroll="load"
      infinite-scroll-disabled="disabled"
    >
      <div
        class="item mr30 ml40 mb40"
        v-for="(item, index) in collectList"
        
        :key="index"
      >
        <el-image
          class="img "
          style="margin-bottom:5px;"
          fit="cover"
          :src="item.logo"
          @click="goDetail(index)"
        />
        <div class="flex justify-between">
          <div class="f21 text-overflow flex1">{{ item.chapterName }}</div>
          <div class="f18 yellow mouse"  @click="cancelCollect(index)">取消收藏</div>
        </div>
      </div>
      <div v-if="loading" class="bottomText">加载中...</div>
      <div v-if="noMore" class="bottomText">没有更多了</div>
    </section>
    <!-- <div class="pageInfo">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.currPage"
        :page-size="queryInfo.pageSize"
        layout=" prev, pager, next,total, jumper"
        :total="count"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        currPage: 1,
        pageSize: 9,
        condition: {
          memberCode: JSON.parse(sessionStorage.getItem("userInfo")).memberCode,
        },
      },
      collectList: [],
      pageCount: 0,
      userInfo: {},
      chapterCode: "",
      chapterDetail: {},
      loading: false,
    };
  },
  computed: {
    noMore() {
      return this.queryInfo.currPage >= this.pageCount;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    load(){
    this.queryInfo.currPage++
    this.loading=true
    this.getMyCollectList()
    },
    //取消收藏
    cancelCollect(index) {
      var getData = {};
      getData.chapterCode = this.collectList[index].chapterCode;
      getData.memberCode = this.userInfo.memberCode;
      this.$api.lesson.cancelCollect(getData).then((res) => {
        if (res.data.code === 200) {
          // this.$message.success("已取消收藏");
          this.$message({
            message: "已取消收藏!",
            type: "success",
             offset:'400'
          });
          this.getMyCollectList(); 

        }
        this.$router.go(1)
      });

    },
    //  handleCurrentChange(newCurr) {
    //   this.queryInfo.currPage = newCurr;
    //   this.getMyCollectList();
    // },
    //我的收藏
    getMyCollectList() {
      var getData = {};
      var condition = {};
      condition.memberCode = this.userInfo.memberCode;
      getData.condition = condition;
      getData.pageSize = this.queryInfo.pageSize;
      getData.currPage = this.queryInfo.currPage;
      this.$api.user.getCollectList(getData).then((res) => {
        //下面是合并数组
        // this.collectList = [...res.data.data.data,...this.collectList];
        this.collectList = res.data.data.data
        this.pageCount = res.data.data.pageCount;
        this.loading=false
      });
    },
    //前往详情
    goDetail(index) {
      this.$router.push({
        path: "/curriculumCollect",
        query:{
            course:JSON.stringify(this.collectList[index]) 
        }
      });
      console.log('list',this.collectList[index])
    },
    

    //课程详情
    goDetail1(index) {
      var getData = {};
      getData.chapterCode = this.collectList[index].chapterCode;
      getData.courseCode = this.collectList[index].courseCode;
      this.$api.lesson.getChapterDetail(getData).then((res) => {
        if (res.data.code == 200) {
          this.chapterDetail = res.data.data;
        }
      });
    },
    // // 修改当前页
    // handleCurrentChange(newCurr) {
    //   this.queryInfo.currPage = newCurr;
    // },

    jump(item) {
      //跳转
      let temp = {};
      if (item.type === "1") {
        temp = {
          path: "/practice/lesson/video",
          query: {
            courseCode: item.courseCode,
          },
        };
      } else {
        temp = {
          path: "/practice/lesson/image",
          query: {
            courseCode: item.courseCode,
          },
        };
      }
      this.$router.push(temp);
    },
  },

  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.getMyCollectList();
  },
};
</script>

<style lang="less" scoped>
//光标设置为手
.mouse{
  cursor: pointer;
}
.content {
  .listBox {
    height: 665px;
    overflow-y: auto;
    .item {
      width: 190px;
      .img {
        width: 190px;
        height: 190px;
        border-radius: 6px;
        overflow: hidden;
      }
      .yellow {
        color: #f8b229;
      }
    }
    .bottomText{
      width: 100%;
    }
  }
  .pageInfo {
    margin-top: 20px;
    text-align: right;
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      color: black;
      background: #f8b229;
    }
  }
}
</style>
