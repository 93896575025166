<template>
  <transition name="fade" appear>
    <div id="refund">
      <div class="box">
        <el-input
          type="textarea"
          autosize
          resize="none"
          placeholder="请输入退款原因"
          v-model="queryInfo.content"
          class="input"
        >
        </el-input>
        <div class="upload flex flex-wrap" >
          <el-upload
            :action="$uploadURL"
            list-type="picture-card"
            :on-preview="previewImg"
            :on-remove="removeImg"
            :before-upload="checkFormat"
            :on-success="uploadImg"
            :limit="fileLimit"
            :multiple="true"
            :on-exceed="showTips"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="isPreview">
            <el-image width="100%" :src="previewItem" alt="" />
          </el-dialog>
        </div>
        <div class="buttonGroup flex">
          
          <el-button class="button button1" @click="cancle">取消</el-button>
            
          <el-button class="button button2" type="warning" @click="jumpDetail(wuliu)"
            >确认退款</el-button
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
 props: {
    
    wuliu: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch:{
    wuliu(newV){
    },
  },
  data() {
    return {
       form:{},
      queryInfo: {
        content: "",
        fileList: [],
        memberCode: "",
      },
      isPreview: false,
      // 文件上传数量限制
      fileLimit: 9,
      // 文件类型
      fileType: "",
      // 预览的图片
      previewItem: "",
      list: "",
      memberCode: "",
    };
  },

  methods: {
    // 判断上传文件类型
    checkFormat(file) {
      const tempList = file.name.split(".");
      const fileType = tempList[tempList.length - 1];
      const imgFile = ["png", "jpg", "jpeg", "bmp", "gif"];
      const videoFile = [
        "mp4",
        "rmvb",
        "avi",
        "flv",
        "m2v",
        "mkv",
        "wmv",
        "mp3",
        "wav",
      ];
      if (!this.fileType) {
        if (imgFile.indexOf(fileType) > -1) {
          this.fileType = "img";
          this.fileLimit = 9;
        } else if (videoFile.indexOf(fileType) > -1) {
          this.fileType = "video";
          this.fileLimit = 1;
        } else {
          this.$message({
            message: "请上传图片或者视频!",
            type: "warning",
             offset:'400'
          });
          return false;
        }
      } else if (this.fileType === "img") {
        if (imgFile.indexOf(fileType) === -1) {
          this.$message({
            message: "请上传图片!",
            type: "warning",
             offset:'400'
          });
          return false;
        }
      }
    },
    // 格式化上传图片源数据类型
    formatUpPic(fileList) {
      this.queryInfo.fileList = fileList.map((v) => {
        return {
          name: v.name,
          url: v.response ? v.response.data.url : v.url,
        };
      });
    },
    //
    showTips(){
      // if (this.queryInfo.fileList.length>9) {
          // this.$message.warning("最多上传9张图片！");
           this.$message({
                    message: "最多上传9张图片！",
                    type: "warning",
                    offset:'400'
                  });
      // }
    },
    // 上传图片
    uploadImg(res, file, fileList) {
      this.formatUpPic(fileList);
    },
    // 预览图片
    previewImg(file) {
      this.isPreview = true;
      this.previewItem = file.url;
    },

    // 删除图片
    removeImg(res, file, fileList) {
      const index = this.queryInfo.fileList.findIndex((v) => {
        return v.name === res.name;
      });
      this.queryInfo.fileList.splice(index, 1);
    },

    cancle(){
       this.$router.go(0)
    },
    jumpDetail() {
      var data = {};
      data.orderRefundEnclosureList = this.queryInfo.fileList;
      data.refundContent = this.queryInfo.content;
      data.memberCode = this.memberCode;
        data.orderStatus = this.wuliu.orderStatus;
      data.orderNumber = this.wuliu.orderNumber;
      if (this.queryInfo.content!='') {
          this.$api.user.refundOrder(data).then((res) => {
        if (res.data.code === 200) {
          console.log('退款返回',res)
          // this.$message.success("申请成功");
           this.$message({
                    message: "申请成功!",
                    type: "success",
                    offset:'400'
                  });
          // this.$router.push({path:"/user"});
           this.$router.go(0)
        }
      });
      }else{
         this.$message({
            message: "请填写退款原因!",
            type: "warning",
            offset:'400'
          });
      }
    
    },
  },

  beforeCreate() {
    this.$nextTick(() => {
      // 修改背景色
      document
        .querySelector("#index")
        .setAttribute("style", "background:#eeeeee");
    });
  },
  beforeDestroy() {
    document
      .querySelector("#index")
      .setAttribute("style", "background:#ffffff");
  },
  created() {
    this.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
  },
};
</script>

<style lang="less" scoped>
.box {
  margin: 0 auto;
  width: 888;
  padding: 70px 70px 95px;
  background: white;
  .input {
    margin-bottom: 30px;
    font-size: 24px;
    /deep/.el-textarea__inner {
      border: none;
      border-radius: 0;
      background: white;
    }
  }
}
.buttonGroup {
  margin-top: 84px;
  .button {
    width: 157px;
    height: 59px;
    margin-right: 153px;
    font-size: 24px;
  }
  .button1 {
    border: 2px solid #e6a23c;
    color: #f3ae28;
  }
}
.upload{
  text-align: left;
}
</style>