<template>
<div>
     <div>
       <div>
         <!-- <div v-if="this.form.expirationTime==null" class="time endTime"> {{endTime}}</div>
         <div v-if="Date.parse(this.dayDate) > this.endTime"
           class="endTime">您的激活码于 <span class="time"> {{endTime|dataFormat}}</span>到期，已过期</div>
        <div v-else class="endTime">您的激活码于 <span class="time"> {{endTime|dataFormat}}</span>到期</div> -->
        <div v-show="showNull"
           class="time endTime">
           <img src="../../../assets/img/user/endTimeMain.png" style="width:600px;height:220px;">
            <span style="position:relative;top:-135px; color:#fff;left:-30px;">当前课程暂未激活</span> 
           </div>
      <div v-show="showNormal"
           class="endTime">
            <img src="../../../assets/img/user/endTimeMain.png" style="width:600px;height:220px;">
           <span class="time" style="position:relative;top:-135px; color:#fff;left:-30px;"> 您的课程于：{{endTime|dataFormat}}到期</span>
           </div>
      <div v-if="showEnd"
           class="endTime">
            <img src="../../../assets/img/user/endTimeMain.png" style="width:600px;height:220px;">
            <span class="time" style="position:relative;top:-135px; color:#fff;left:-30px;font-size:17px;"> 您的课程于：{{endTime|dataFormat}}到期，已过期</span>
           </div>
    </div>
       </div>
        <div style="text-align: center; margin-top:30%;margin-left:12%;" v-show="showBtn"  @click="open" class="pointer">
          <!-- <el-button type="primary" @click="open" style="background:#fab300;border:none;margin-top:20%;margin-left:5%;">点击激活</el-button> -->
             <img src="../../../assets/img/user/click.png" style="width:125px;height:125px;" >
        </div>
     </div>
</template>

<script>
export default {
    data () {
        return {
            form:{},
            endTime:'',
            memberCode:'',
            dayDate:'',
            showNull: false,
            showNormal: false,
            showEnd: false,
            showBtn:false,
        }
    },
    methods:{
_getUserInfo() {
      //获取用户信息
      this.$api.user.getUserInfo(
        { memberCode: this.memberCode }
      ).then(res => {
        if (res.data.code === 200) {
          this.form = res.data.data
            this.showEndTime()
            console.log('激活', this.form )
        }
      })
    },
      showEndTime(){
        if (this.form.expirationTime == null) {
        this.endTime = '当前课程暂未激活';
        this.showNormal = false;
        this.showEnd = false;
        this.showNull = true;
        this.showBtn = true;
      } if (Date.parse(this.dayDate) > this.endTime) {
        this.endTime = this.form.expirationTime
        this.showNull = false;
        this.showNormal = false;
        this.showEnd = true;
        this.showBtn = true;
      } if (Date.parse(this.dayDate) < this.endTime && this.form.expirationTime != null) {
        this.endTime = this.form.expirationTime
        this.showNull = false;
        this.showEnd = false;
        this.showBtn = false;
        this.showNormal = true;
      }
      },
      day () {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate(),
      }
      this.dayDate = date.year + '-' + (date.month >= 10 ? date.month : '0' + date.month) + '-' + (date.day >= 10 ? date.day : '0' + date.day)
      console.log(this.dayDate);
    },
     open() {
        this.$prompt('请输入激活码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPlaceholder:'激活码位于随书家庭学习卡'
        }).then(({ value }) => {
          var getData={}
          getData.memberCode=this.memberCode
          getData.activationCode=value
          this.$api.user.updataActivation(getData).then((res) => {
            console.log('返回',res.data.data)
          if (res.data.code == 200) {
              this._getUserInfo()
                // this.$message.success('激活成功')
                 this.$message({
                    message: "激活成功!",
                    type: "success",
                    offset:'400'
                  });
             }else{
                 this.$message({
                    message: "该激活码已被使用了!",
                    type: "success",
                    offset:'400'
                  });
             }
          })
        }).catch(() => {
          
        });
      }
    },
    created() {
    this.memberCode = JSON.parse(sessionStorage.getItem('userInfo')).memberCode
    this._getUserInfo()
    this.day () 
  }

}
</script>

<style scoped>
.endTime{
    /* background: lightgrey; */
    width: 500px;
    height: 50px;
    margin-left: 25%;
    line-height: 50px;
    font-size: 20px;
    margin-top: 11%;
}
.time{
    color: #fab300;
}

</style>