<template>
  <div>
    <div class="flex justify-between">
      <span class="f18">基本信息</span>
      <!-- <span class="blue pointer" @click="update">修改</span> -->
    </div>
    <div class="line"></div>
   <div style="display:flex;  flex-direction: row;">
    <div class="flex justify-center align-center uploadBox " :class="[form.codeStatus=='1' ? 'VipBorder':'']">
      <el-upload
        class="avatar-uploader"
        accept="image/png,image/gif,image/jpg,image/jpeg"
        :show-file-list="false"
        :action="$uploadURL"
        :on-success="uploadSuccess"
      >
        <el-image v-if="form.logo" class="img" :src="form.logo" fit="fill" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>
     <div style="margin-left:40%;">
       <div style="width:365px;height:74px;" v-show="showEndTime">
         <img src="../../../assets/img/user/endTime.png" style="width:100%;height:100%;" >
         <span  style="position:relative;top:-40px;left:-40px;color: #fff;;font-size:15px;">课程到期时间：{{form.expirationTime|dataFormat}}</span>
       </div>
        <div style="width:365px;height:74px;" v-show="showOutTime">
         <img src="../../../assets/img/user/endTime.png" style="width:100%;height:100%;" >
         <span  style="position:relative;top:-40px;left:-40px;color: #fff;;font-size:15px;">到期时间：{{form.expirationTime|dataFormat}}，已过期</span>
       </div>
        <div style="width:365px;height:74px;" v-show="showNot">
         <img src="../../../assets/img/user/endTime.png" style="width:100%;height:100%;" >
         <span  style="position:relative;top:-40px;left:-40px;color: #fff;;font-size:15px;">当前课程暂未激活</span>
       </div>
       <div style="width:235px;height:70px;margin-left:35%;" v-show="showClick">
         <img src="../../../assets/img/user/active.png" style="width:100%;height:100%;"  @click="open">
         <span class="showCussor" style="position:relative;top:-42px;left:-40px;color: #fff;;font-size:15px;"  @click="open">点此激活</span>
       </div>
     </div>
     </div>
    <el-form
      :model="form"
      status-icon
      ref="form"
      label-width="100px"
      class="form"
      label-position="left"
    >
      <el-form-item label="昵称：" prop="pass">
        <el-input
          type="text"
          v-model="form.nickName"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="公司名称：" prop="checkPass">
        <el-input type="text" v-model="form.checkPass" autocomplete="off"></el-input>
      </el-form-item> -->
      <el-form-item label="手机号：" prop="age">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
      <div>
        <el-button type="primary" style="background:#f8b229;border:none;width:120px;margin-top:20%;" @click="update">保存</el-button>
      </div>
      <!-- <div class="flex justify-between" style="margin-top: 30px">
        <span class="f18">账号信息</span>
        <span class="blue pointer">修改</span>
      </div>

      <div class="line"></div>

      <el-form-item label="账号：" prop="age">
        <el-input
          disabled
          v-model.number="form.loginName"
          class="form-input"
        ></el-input>
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
      member: "",
      form: {},
      memberCode: '',
      showEndTime:false,
      showOutTime:false,
      showNot:false,
      showClick:false,
    };
  },
  methods: {
    uploadSuccess(response) {
      this.form.logo = response.data.url;
      this.$store.commit('updateMemberLogo', this.form.logo)
      sessionStorage.setItem('userInfo', JSON.stringify(this.form))

      // localStorage.setItem("ssss","2")
    },

    _getUserInfo() {
      //获取用户信息
      this.$api.user.getUserInfo(
        { memberCode: this.memberCode }
      ).then(res => {
        if (res.data.code === 200) {
          this.form = res.data.data
          console.log('####',res.data.data)
          if(res.data.data.codeStatus=='0'){
            this.showEndTime=false
            this.showOutTime=false
            this.showNot=true
            this.showClick=true
          } if (res.data.data.codeStatus=='1'){
             this.showEndTime=true
            this.showOutTime=false
            this.showNot=false
            this.showClick=false
          } if(res.data.data.codeStatus=='2'){
            this.showEndTime=false
            this.showOutTime=true
            this.showNot=false
            this.showClick=true
          }
        }
      })
    },
     open() {
        this.$prompt('请输入激活码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPlaceholder:'激活码位于随书家庭学习卡'
        }).then(({ value }) => {
          var getData={}
          getData.memberCode=this.memberCode
          getData.activationCode=value
          this.$api.user.updataActivation(getData).then((res) => {
            console.log('返回',res.data.data)
          if (res.data.code ==200) {
              this._getUserInfo()
                // this.$message.success('激活成功')
                 this.$message({
                    message: "激活成功!",
                    type: "success",
                    offset:'400'
                  });
             }else{
                this.$message({
                    message: "该激活码已被使用了!",
                    type: "success",
                    offset:'400'
                  });
             }
          })
        }).catch(() => {
          
        });
      },
    update() {
      //修改用户信息
      this.$api.user.updateUserInfo(
        {
          ...this.form,
        }
      ).then(res => {
        if (res.data.code = 200) {
          this.$store.commit('updateMemberName', this.form.nickName)
          sessionStorage.setItem('userInfo', JSON.stringify(this.form))
          // this.$message.success('修改成功')
          this.$message({
            message: "修改成功!",
            type: "success",
            offset:'400'
          });
          //刷新页面
          this.$router.go(0)
        }
      })
    }

  },

  created() {
    this.memberCode = JSON.parse(sessionStorage.getItem('userInfo')).memberCode

    this._getUserInfo()

    // console.log("imgurl",localStorage.getItem('ssss'))
  }
};
</script>

<style lang="less" scoped>
.line {
  margin: 20px 0;
  border-top: 1px solid #c1c4cc;
}
.uploadBox {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  font-size: 40px;
  color: #c1c4cc;
  background: #f4f4f4;
  overflow: hidden;
}
.img {
  width: 150px;
  height: 150px;
  text-align: center;
}
.form {
  margin-top: 20px;
  // .form-input {
  //   // margin-top: 30px;
  // }
}
.blue {
  color: #47bcff;
}
/deep/ .el-form-item__label {
  font-size: 16px;
}
.VipBorder{
  border: 5px solid #f8b229;
}
.showCussor:hover{
  cursor: pointer;
}
</style>
