<template>
  <div id="address">
    <section class="addressBox" v-show="!isAddAddress">
      <el-button class="btn" @click="addNewAddress">新增收货地址</el-button>
      <div class="list">
        <template v-for="(item, index) in addressList">
          <div :key="index" class="item positionr mb30">
            <div class="mb10">收货人：{{ item.consigneeName }}</div>
            <div class="mb10">
              所在地区： {{ item.province }} {{ item.city }} {{ item.area }}
            </div>
            <div class="mb10">地址： {{ item.detail }}</div>
            <div class="mb10">手机： {{ item.consigneePhone }}</div>
            <div class="defaultAdd pointer" v-if="item.isDefault == '1'">默认地址</div>
            <div class="edit flex align-center" @click="getAddressList">
              <div class="mr20 mouse" @click="editAddress(item)">编辑</div>
              <div class="mouse"  @click="del(index)">删除</div>
            </div>
          </div>
        </template>
      </div>
    </section>

    <section class="addressDetail" v-show="isAddAddress">
      <div class="title">编辑收货地址</div>
      <el-form :rules="rules" ref="form" :model="form" label-position="left">
        <el-form-item class="flex" label="地址信息：" prop="mainAdd">
          <el-cascader
            v-model="province"
            :options="citys"
            @change="chooseProvince"
            :props="prop"
          ></el-cascader>
        </el-form-item>
        <el-form-item class="flex" label="详细地址：" prop="detailAdd">
          <el-row>
          <el-input
            type="textarea"
            v-model="form.detailAdd"
            :autosize="{ minRows: 2 }"
            resize="none"
          ></el-input>
          </el-row>
        </el-form-item>
        <el-form-item class="flex" label="邮政编码：" prop="emailCode">
          <el-row> <el-input v-model="form.emailCode"></el-input></el-row>
        </el-form-item>
        <el-form-item class="flex" label="收货人姓名：" prop="consigneeName">
           <el-row><el-input v-model="form.consigneeName"></el-input></el-row>
        </el-form-item>
        <el-form-item class="flex" label="手机号码：" prop="consigneePhone">
          <div class="flex">
            <el-select v-model="form.areaCode" >
              <el-option label="中国大陆 +86" value="86"></el-option>
              <!-- <el-option label="中国大陆 +86" value="86"></el-option> -->
            </el-select>
           <el-row>  <el-input class="ml20" v-model="form.consigneePhone"></el-input></el-row>
          </div>
        </el-form-item>
        <el-form-item class="ml125">
          <el-checkbox v-model="form.isDefault">设置为默认地址</el-checkbox>
        </el-form-item>
        <el-form-item class="ml125">
          <el-button class="btn" type="primary" @click="submit">保存</el-button>
        </el-form-item>
      </el-form>
      
    </section>
    
  </div>
</template>

<script>
import city from "../../../../public/city.json";
export default {
  props: {
    isAdd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      province: "",
      chosenProvince: "",
      chosenCity: "",
      chosenArea: "",
      addressList: [],
      // 是否编辑地址
      isEdit: false,
      isAddAddress: this.isAdd,
      rules: {
        mainAdd: [
          { required: false, message: "请选择地址", trigger: "change" },
        ],
        detailAdd: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
        emailCode: [{ required: true, message: "请填写邮箱", trigger: "blur" }],
        consigneeName: [
          { required: true, message: "请输入收件人姓名", trigger: "blur" },
        ],
        consigneePhone: [
          { required: true, message: "请输入收件人联系方式", trigger: "blur" },
        ],
      },
      citys: [],
      prop: {
        value: "value",
        label: "value",
        checkStrictly: true,
      },
      form: {
        detailAdd: "",
        emailCode: "",
        consigneeName: "",
        consigneePhone: "",
        isDefault: false,
      },
    };
  },
  methods: {
    chooseProvince(val) {
      this.chosenProvince = val[0];
      this.chosenCity = val[1] || "";
      this.chosenArea = val[2] || "";
    },
    del(index) {
      var getData = {};
      getData = this.addressList[index];
      getData.memberCode = this.memberCode;
      this.$api.user.deleteAddress(getData).then((res) => {
        if ((res.data.code = 200)) {
          // this.$message.warning("删除成功");
           this.$message({
            message: "删除成功!",
            type: "warning",
            offset:'400'
          });
          this.getAddressList();
        }
      });
    },
    getAddressList() {
      var getData = {};
      getData.memberCode = this.memberCode;
      this.$api.notice.getAddressList(getData).then((res) => {
        if ((res.data.code ===200)) {
          this.addressList = res.data.data;
        }
      });
    },
    editAddress(item) {
      this.isAddAddress = true;
      this.isEdit = true;
      this.id = item.id;
      this.province=[item.province,item.city,item.area]
      this.chosenProvince = item.province;
      this.chosenCity = item.city || "";
      this.chosenArea = item.area || "";
      this.form = {
        detailAdd: item.detail,
        emailCode: item.emailCode,
        consigneeName: item.consigneeName,
        consigneePhone: item.consigneePhone,
        isDefault: item.isDefault == "1" ? true : false,
      };
    },
    addNewAddress() {
      this.isAddAddress = true;
    },

    submit() {
      var getData = {
        city: this.chosenCity,
        province: this.chosenProvince,
        area: this.chosenArea,
        memberCode: this.memberCode,
        emailCode: this.form.emailCode,
        consigneeName: this.form.consigneeName,
        consigneePhone: this.form.consigneePhone,
        detail: this.form.detailAdd,
        isDefault: this.form.isDefault == false ? "0" : "1",
      };
      if (this.isEdit == false) {
        this.$api.user.addAddress(getData).then((res) => {
          if (res.data.code == 200) {
            // this.$message.success("添加成功");
             this.$message({
            message: "添加成功!",
            type: "success",
            offset:'400'
          });
            this.isAddAddress = false;
            this.getAddressList();
            this.clearData();
          }
        });
      } else {
        (getData.id = this.id),
          this.$api.user.updateAddress(getData).then((res) => {
            if (res.data.code == 200) {
              // this.$message.success("更新成功");
                this.$message({
            message: "更新成功!",
            type: "success",
            offset:'400'
          });
              this.getAddressList();
              this.clearData();
            }
          });
      }
    },
    // 清空数据
    clearData() {
      this.form = {
        detailAdd: "",
        emailCode: "",
        consigneeName: "",
        consigneePhone: "",
        isDefault: false,
      };
      this.isAddAddress = false;
      this.isEdit = false;
      this.id = "";
      this.province = "";
      this.chosenProvince = "";
      this.chosenCity = "";
      this.chosenArea = "";
    },
  },
  mounted() {},
  beforeCreate(){
    this.$nextTick(()=>{
    document.querySelector('#index').setAttribute('style','background:#eeeeee')
    })
  },
  beforeDestroy(){
    document.querySelector('#index').setAttribute('style','background:#ffffff')
  },
  created() {
    this.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
    this.getAddressList();
    this.citys = city.provinces;
  },
};
</script>

<style lang="less" scoped>
//光标设置为手
.mouse{
  cursor: pointer;
}
#address {
  height: 100%;
  padding-left: 20px;
  text-align: left;
}
.addressBox {
  height: 100%;
}
.btn {
  width: 175px;
  height: 34px;
  margin-bottom: 30px;
  font-size: 17px;
  line-height: 10px;
  color: white;
  background: #f8b229;
}
.list {
  height: 100%;
  overflow-y: auto;
  .item {
    padding: 36px 34px 20px;
    font-size: 18px;
    background: white;
    border-radius: 6px;
    .defaultAdd {
      position: absolute;
      top: 30px;
      right: 34px;
      color: red;
      font-size: 18px;
    }
    .edit {
      position: absolute;
      bottom: 30px;
      right: 34px;
      color: #0886f0;
      font-size: 17px;
    }
  }
}
.addressDetail {
  .title {
    margin-bottom: 50px;
    font-size: 17px;
    color: #ec3511;
  }
  /deep/.el-form-item__label {
    width: 125px;
    font-size: 17px;
  }
  /deep/.el-cascader {
    width: 100%;
  }
  /deep/.el-form-item__content {
    flex: 1;
    font-size: 17px;
  }
  /deep/.el-checkbox__inner {
    width: 24px;
    height: 24px;
    color: black;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: #4f74eb;
  }
  /deep/.el-checkbox__inner::after {
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 15px;
    left: 9px;
    position: absolute;
    top: 1px;
    transform: rotate(45deg) scaleY(1);
    width: 5px;
    transition: transform 0.15s ease-in 0.05s;
    transform-origin: center;
  }
  /deep/.el-checkbox__label {
    font-size: 17px;
    color: black;
  }
  .btn {
    width: 114px;
    height: 45px;
    font-size: 17px;
    color: white;
    line-height: 5px;
    background: #4f74eb;
  }
}
</style>