<template>
  <div>
    <div class="flex justify-between">
      <span class="f18">修改密码</span>
      <!-- <span class="blue pointer" @click="update">修改</span> -->
    </div>
    <div class="line"></div>

    <el-form
      :model="form"
      status-icon
      ref="form"
      label-width="100px"
      class="form"
      label-position="left"
    >
      <el-form-item label="原密码：" prop="pass">
        <el-input
          type="password"
          v-model="form.passwordOld"
          :minlength="6"
          :maxlength="16"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码：" prop="age">
        <el-input
          type="password"
          v-model="form.password"
          :minlength="6"
          :maxlength="16"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" prop="age">
        <el-input
          type="password"
          v-model="form.passwordAver"
          :minlength="6"
          :maxlength="16"
        ></el-input>
      </el-form-item>
    </el-form>
    <div style="margin-top:15%;">
      <button  @click="update" class="changeBtn">修改</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",

      form: {
        passwordOld: "",
        password: "",
        passwordAver: "",
        memberCode: "",
      },
      rules: {
        passwordOld: [
          {
            required: true,
            message: "请输入6-16位密码，区分大小写",
            trigger: "blur",
          },
          {
            pattern: /^[0-9A-Za-z]{6,16}$/,
            message: "请输入正确的密码格式",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入6-16位密码，区分大小写",
            trigger: "blur",
          },
          {
            pattern: /^[0-9A-Za-z]{6,16}$/,
            message: "请输入正确的密码格式",
            trigger: "blur",
          },
        ],
        passwordAver: [
          { required: true, message: "请确认密码", trigger: "blur" },
          {
            pattern: /^[0-9A-Za-z]{6,16}$/,
            message: "请输入正确的密码格式",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    update() {
      //修改用户信息
      // var getData={};
      // getData.memberCode=this.memberCode;
      // getData.passWordOld=this.passWordOld;
      // getData.password=this.password;
      // getData.passwordAver=this.passwordAver
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.password === this.form.passwordAver) {
            this.$api.user.updatePassword(this.form).then((res) => {
              if (res.data.code === 200) {
                this.$message.success("修改密码成功").then( this.$confirm("修改密码成功,请重新登陆!", "提示", {
                  confirmButtonText: "确定",
                  type: "warning",
                }).then(() => {
                  this.$router.push({ path: "/login" });
                }));
                  //
               
              }
            });
            //
          } else {
            // this.$message.warning("两次输入密码不一致");
            this.$message({
            message: "两次输入密码不一致!",
            type: "warning",
             offset:'400'
          });
          }
        }
      });
    },
  },

  created() {
    this.form.memberCode = JSON.parse(
      sessionStorage.getItem("userInfo")
    ).memberCode;
  },
};
</script>

<style lang="less" scoped>
.line {
  margin: 20px 0;
  border-top: 1px solid #c1c4cc;
}
.uploadBox {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-size: 40px;
  color: #c1c4cc;
  background: #f4f4f4;
  overflow: hidden;
}
.img {
  width: 70px;
  height: 70px;
  text-align: center;
}
.form {
  margin-top: 20px;
  .form-input {
    margin-top: 30px;
  }
}
.blue {
  color: #47bcff;
}
/deep/ .el-form-item__label {
  font-size: 16px;
}
.changeBtn{
  width: 100pt;
  height: 30pt;
  border: none;
  background: rgb(248,178,41);
  color: white;
  border-radius: 3pt;
  font-size: 12pt;
}
</style>
