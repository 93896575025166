<template>
  <div id="myOrder">
    <section v-show="!isDetail && !isRefund">
      <nav class="flex align-center mb25">
        <template v-for="(item, index) in navList">
          <div
            class="item mr30 pointer"
            :class="[index === currIndex ? 'activeNav' : '']"
            :key="index"
            @click="chooseNav(index)"
          >
            {{ item.label }}
          </div>
        </template>
      </nav>
      <section
        class="list"
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
      >
        <div class="item mb20" v-for="(item, index) in orderList" :key="index">
          <div class="orderNum flex align-center">
            <div class="mr40">{{ item.createTime | dataFormat }}</div>
            <div class="mr40">订单号：{{ item.orderNumber }}</div>
            <div class="allMoney">
              ￥{{ item.orderAmount }}(含运费：{{ item.mailAmount }})
            </div>
          </div>
          <div class="flex content positionr">
            <template v-for="sitem in item.orderItemList">
              <div :key="sitem.id" class="item">
                <el-image :src="sitem.logo" class="img" fit="cover" />
                <div class="flex flex-column justify-between ml20">
                  <div class="text-left f19 pt5">{{ sitem.productName }}</div>
                  <div class="text-left">
                    <div class="f17">{{ sitem.normName }}</div>
                    <div class="flex align-center">
                      <div class="f17 mr250">数量 {{ sitem.num }}</div>
                      <div class="f18">
                        ￥{{ sitem.price }} (另运费：{{ sitem.mailAmount }})
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="checkBox" v-show="item.orderStatus == 0">
              <el-button class="btn" @click="setRefund(item)">申请退款</el-button>
              <div class="check_text pointer">等待发货</div>
            </div>
            <div class="checkBox" v-show="item.orderStatus == 1">
              <div class="btnGroup">
                <el-button class="btn btn_yellow" @click="ensureGet(item)"
                  >确认收货</el-button
                >
                <el-button class="btn" @click="setRefund(item)">申请退款</el-button>
              </div>

              <div class="check_text pointer" @click="goLogisticsInfo(index)">
                查看物流
              </div>
            </div>
            <div class="checkBox" v-show="item.orderStatus == 2">
              <el-button class="btn" @click="setRefund(item)">申请退款</el-button>
              <div class="check_text pointer">已完成</div>
              <div class="check_text pointer" @click="goLogisticsInfo(index)">查看物流</div>
            </div>
            <div class="checkBox" v-show="item.orderStatus == 3">
              <!-- <el-button class="btn" @click="revokeRefund">取消申请</el-button> -->
              <div class="check_text pointer">退款中...</div>
              <div class="check_text pointer" @click="goLogisticsInfo(index)">查看物流</div>
            </div>
            <div class="checkBox" v-show="item.orderStatus == 4">
              <div class="check_text pointer">已退款</div>
              <div class="check_text pointer" @click="goLogisticsInfo(index)">查看物流</div>
            </div>
          </div>
        </div>
        <div v-if="loading">加载中...</div>
        <div v-if="noMore">没有更多了</div>
      </section>
    </section>
    <section class="logisiticsInfo" v-show="isDetail">
      <LogisiticsInfo :wuliu="wuliu" />
    </section>
    <section class="refund" v-show="isRefund">
      <Refund :wuliu="wuliu1" />
    </section>
  </div>
</template>

<script>
import LogisiticsInfo from "../components/LogisticsInfo.vue";
import Refund from "./Refund.vue";
export default {
  components: {
    LogisiticsInfo,
    Refund,
  },
  data() {
    return {
      
      tempIndex:"",
      navList: [
        { label: "全部" },
        { label: "待发货" },
        { label: "待收货" },
        { label: "已收货" },
        { label: "售后/退款" },
      ],
      currIndex: 0,
      orderList: [],
      currPage: 1,
      pageCount: 0,
      // 退款页面
      isRefund: false,
      loading: false,
      //   是否展示物流
      isDetail: false,
      wuliu: {},
      wuliu1:{},
    };
  },
  computed: {
    noMore() {
      return this.currPage >= this.pageCount;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    clearData(){
    this.orderList=[]
    this.currPage=1
    },
    chooseNav(index) {
      this.clearData()
      this.currIndex = index;
      let tempIndex=''
      if(index==0){
        tempIndex="6"
      }else if(index==1){
        tempIndex="0"
      }else if(index==2){
        tempIndex="1"
      }else if(index==3){
        tempIndex="2"
      }else if(index==4){
        //退款中，已退款
        tempIndex="5"
      }else{
        tempIndex=""
      }
      this.goMyOrder(tempIndex)
    },

    goMyOrder(tempIndex) {
      const param = {
        currPage: this.currPage,
        pageSize: 10,
        condition: {
          memberCode: this.memberCode,
          orderStatus:tempIndex,
        },
      };
      this.$api.user.goMyOrder(param).then((res) => {
        if (res.data.code === 200) {
          console.log('订单',res.data.data.data)
          this.orderList = [...res.data.data.data, ...this.orderList];
          this.pageCount = res.data.data.pageCount;
          this.loading = false;
          // this.navList=res.data.data.data.orderStatus;
        }
      });
    },
    
    goLogisticsInfo(index) {
      let getData = {};
      getData.phone = this.orderList[index].consigneePhone;
      getData.num = this.orderList[index].courierNumber;
      this.$api.user.goLogisticsInfo(getData).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data.body) {
            this.wuliu = JSON.parse(res.data.data.body);
            this.isDetail = true;
          
          }
        }
      });
    },
    load() {
      this.currPage++;
      this.loading = true;
    
    },
    // 申请退款
    setRefund(item) {
      this.isRefund = true;
       this.wuliu1 = item;
    },
    // 取消退款
    revokeRefund() {},
    // 确认收货
    // ensureGet(item) {
    //   this.$confirm("确认已经收到货物？", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.$message({
    //         type: "success",
    //         message: "收货完成!",
    //       });
           
    //     })
    //     .catch(() => {});
    // },
     ensureGet(item) {
       var param={
         id:item.id,
         orderStatus:"2"
       }
       this.$api.user.updateOrder(param).then((res) => {
        if (res.data.code == 200) { 
          // this.$message.success('收货成功')  
          this.$message({
            message: "收货成功!",
            type: "success",
             offset:'400'
          });
          this.$router.go(0)
        }
      });
     },
  },

  created() {
    this.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
    this.chooseNav(0);
  },
};
</script>

<style lang="less" scoped>
#myOrder {
  height: 100%;
}
nav {
  border-bottom: 2px solid #f8b229;
  .item {
    width: 117px;
    height: 38px;
    font-size: 21px;
    line-height: 37px;
  }
  .activeNav {
    color: white;
    background: #f8b229;
  }
}
.list {
  overflow: auto;
  height: 600px;
  .item {
    width: 873px;
    padding-bottom: 5px;
    font-size: 19px;
    border-radius: 6px;
    background: white;
    overflow: hidden;
    .orderNum {
      height: 40px;
      padding-left: 18px;
      line-height: 40px;
      background: #fef0d3;
      .allMoney {
        margin-left: auto;
        padding-right: 30px;
      }
    }

    .content {
      min-height: 140px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 10px 18px 18px;
      .item {
        display: flex;
        .img {
          width: 119px;
          height: 110px;
          border-radius: 5px;
        }
      }

      .checkBox {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        .btnGroup{
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .btn {
          height: 34px;
          margin-bottom: 10px;
          font-size: 17px;
margin-left: 0;
          line-height: 5px;
        }
        .btn_yellow {
          color: white;
          background: #f8b229;
        }
        .check_text {
          font-size: 17px;
          color: #f8b229;
        }
      }
    }
  }
}
.logisiticsInfo {
  height: 100%;
  background: white;
}
</style>