<template>
  <transition name="fade" appear>
    <div style="padding-bottom: 40px">
      <div class="content flex">
        <div class="left flex flex-column">
          <div
            :class="[currIndex === item.id ? 'activeNav' : '', 'item-d', 'pointer']"
            v-for="item in menuList"
            :key="item.id"
            @click="changeTab(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="flex1 right">
          <BaseInfo v-if="currIndex === 0" />
          <Collection v-if="currIndex === 1" />
          <MyOrder v-if="currIndex === 2" />
          <Address v-if="currIndex === 3" :isAdd='isAdd'/>
          <Declare v-if="currIndex === 4" />
          <UpdatePwd v-if="currIndex === 5" />
          <Connect v-if="currIndex === 6" />
          <ShowEndTime v-if="currIndex === 7" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BaseInfo from "@/views/user/components/BaseInfo";
import Collection from "@/views/user/components/Collection";
import Declare from "@/views/user/components/Declare";
import Address from "@/views/user/components/Address";
import Connect from "@/views/user/components/Connect";
import UpdatePwd from "@/views/user/components/Password";
import MyOrder from "@/views/user/components/MyOrder";
import ShowEndTime from "@/views/user/components/ShowEndTime";
export default {
  components: {
    BaseInfo,
    Collection,
    Declare,
    Address,
    Connect,
    UpdatePwd,
    MyOrder,
    ShowEndTime,
  },
  data() {
    return {
      currIndex: 0,
      menuList: [
        { name: "个人资料", id: 0 },
        { name: "我的收藏", id: 1 },
        { name: "我的订单", id: 2 },
        { name: "我的地址", id: 3 },
        { name: "我的发布", id: 4 },
        { name: "修改密码", id: 5 },
        // { name: "我的消息", id: 6 },
        { name: "课程激活", id: 7 },
      ],
      isAdd:false
    };
  },

  methods: {
    changeTab(item) {
      this.currIndex = item.id;
    },
  },
  beforeCreate() {
    this.$nextTick(() => {
      document.querySelector("#index").setAttribute("style", "background:#F2F1F1");
    });
  },
  beforeDestroy() {
    document.querySelector("#index").setAttribute("style", "background:#ffffff");
  },
  created(){
    if(this.$route.query.myAddress==='checkAddress'){
      this.currIndex=3
    }else if(this.$route.query.myAddress==='addAddress'){
      this.currIndex=3
      this.isAdd=true
    }
  },
};
</script>

<style lang="less" scoped>
.content {
  height: 695px;

  .left {
    width: 190px;
    background: #eae3d9;
    overflow-x: hidden;
    .item-d {
      font-size: 18px;
      height: 78px;
      line-height: 78px;
    }
    .activeNav {
      color: white;
      background: #f8b229;
    }
  }
  .right {
    padding: 30px;
    // background: #f9fbfc;
  }
}
</style>
